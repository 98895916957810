import { connect } from 'react-redux';

const redux_connect = function (Component, stores) {
	function mapStateToProps(state) {
		let st = {};

		for (let i in stores) {
			st[i] = state[stores[i]];
		}

		return st;
	}

	let dispatch;

	function mapDispatchToProps(d) {
		dispatch = d;
		return {};
	}

	function mergeProps(stateProps, dispatchProps, ownProps) {
		return Object.assign({ dispatch }, stateProps, ownProps);
	}

	return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
};

export default redux_connect;

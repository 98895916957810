import React from 'react';

import HeaderAboveContent from 'resources/assets/react-app/components/HeaderAboveContent';
import PostFooter from 'resources/assets/react-app/components/PostFooter';
import PreHeader from 'resources/assets/react-app/components/PreHeader';
import Footer from './Footer';
import TopNav from './TopNav';

import modal from 'Demora/modals/modal';

import cookieconsent from 'cookieconsent';
import 'cookieconsent/build/cookieconsent.min.css';

import ReduxConnect from 'Demora/redux-connect';

import Router from '../app/Router';

import { withRouter } from 'react-router-dom';

import loadinggif from '../../../../images/loading2.gif';

import config from 'resources/assets/react-app/config';

import Announcements from './Announcements';

var cookieconsent1 = cookieconsent;

class App extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			user: props.user.id,
			loaded: false,
			path: null,
			TopNavAuth: undefined,
		};
	}

	componentDidMount() {
		window.cookieconsent.initialise({
			theme: 'ruf',
			position: 'bottom-right',
		});

		const ref = new URLSearchParams(this.props.location.search).get(
			'dem_rf0'
		);

		if (ref) {
			this.props.dispatch({
				type: 'RECORD_REFERER_USERNAME',
				referer: ref,
			});
		}

		if (this.props.user.id) {
			this.loadAuthFiles();
		} else {
			this.setState({
				loaded: true,
			});
		}
	}

	componentDidUpdate() {
		if (this.props.user.id && !this.state.user) {
			this.setState({
				user: this.props.user.id,
			});

			this.loadAuthFiles();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let ret = {};

		let location = nextProps.location;

		let fullPath = location.pathname + '?' + location.search;

		if (fullPath !== prevState.path) {
			ret.path = fullPath;

			let element = document.getElementsByTagName('body')[0];

			if (config.hideHeader && config.hideHeader(location)) {
				element.classList.add('hide-header');
			} else {
				element.classList.remove('hide-header');
			}

			if (config.hideFooter && config.hideFooter(location)) {
				element.classList.add('hide-footer');
			} else {
				element.classList.remove('hide-footer');
			}
		}

		if (nextProps.user.id && !prevState.user) {
			ret.loaded = false;
		}

		if (Object.keys(ret).length == 0) {
			return null;
		}

		return ret;
	}

	loadAuthFiles() {
		Promise.all([
			import(/* webpackChunkName: "auth_stuff" */ './TopNavAuth'),
			import(
				/* webpackChunkName: "auth_stuff" */ '@fortawesome/fontawesome-free/css/all.css'
			),
			import(
				/* webpackChunkName: "auth_stuff" */ 'resources/assets/react-app/sass/demora_auth.scss'
			),
		])
			.then((a) => {
				this.setState({
					TopNavAuth: a[0].default,
					loaded: true,
				});
			})
			.catch((e) => {
				throw e;
			});
	}

	render() {
		let state = this.state;

		let re = /[^a-z0-9_]/gi;

		let urlClassname = location.pathname.replace(re, '_');

		if (!state.loaded) {
			return (
				<div className='text-center'>
					<img src={loadinggif} style={{ maxWidth: '250px' }} />
					<br />
					<h3>Loading</h3>
				</div>
			);
		}

		if (this.props.user.id) {
			urlClassname += ' authorisedUser';
		}

		let V = state.TopNavAuth;

		return (
			<div id='appContainer'>
				<PreHeader />

				<div className={urlClassname}>
					{V ? <V /> : <TopNav />}
					<div id='pageContent'>
						<HeaderAboveContent />
						<Announcements />
						<Router />
					</div>
					<Footer />

					{modal.show()}
				</div>

				<PostFooter />
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(App, {
		modals: 'modals', // required to trigger re-render for modals
		user: 'user',
	})
);
